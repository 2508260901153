/* global I18n app */

I18n.defaultLocale = 'cs'

I18n.tc = function(scope, opts) {
  return I18n.t(scope.replace(/([A-Z])/g, function($1) {
    return '_' + $1.toLowerCase()
  }), opts)
}

I18n.pczGetCurrencyUnit = function() {
  return I18n.t(`number.currency.${app.currency}.unit`)
}

I18n.pczToCurrency = function(scope, opts) {
  if (!opts) {
    opts = {}
  }

  const allowed = ['precision', 'delimiter', 'format', 'unit', 'separator', 'significant', 'strip_insignificant_zeros']

  allowed.forEach(key => {
    opts[key] = opts[key] !== undefined ? opts[key] : I18n.t(`number.currency.${app.currency}.${key}`)
  })

  return I18n.toCurrency(scope, opts)
}

I18n.getOrdinal = function(n) {
  switch (I18n.locale || I18n.defaultLocale) {
    case 'en': {
      const s = ['th', 'st', 'nd', 'rd']
      const v = n % 100

      return n + (s[(v - 20) % 10] || s[v] || s[0])
    }
    case 'cs':
      return n + '.'
    default:
      return n
  }
}

I18n.pluralization.cs = function(count) {
  count = Math.abs(count)
  switch (true) {
    case count == 0:
      return ['zero', 'other']
    case count == 1:
      return ['one']
    case count > 1 && count < 5:
      return ['few']
    default:
      return ['other']
  }
}
